.link{
	color: #0d6efd !important;
	cursor: pointer;
}
.invalid-feedback{
	font-size: 17px !important;
}
.main-sidebar-body li.nav-item.active .nav-link{
	margin: 0px auto !important; 
	padding: 10px !important;
	padding-left: 15px !important;
}

.table-loader{
  background: rgb(255, 248, 236);
  width: 100%;
  height: 3000px;
  position: relative;
  z-index: 999999999999;
  background: transparent;
  background: white;
}
.table-loader-img{
  position: absolute;
  right: 0;
  bottom: 0;
  top: 30vh;
  left: 0;
  margin: 0 auto;
  text-align: center;
}

:root {
  --dark-theme:#282d3e;
  --dark-body:#3c3f4e;
  --primary-bg-color :#A1247B !important;
  --primary-bg-hover :rgba(77, 101, 217, 0.8);
  --primary-bg-border :#A1247B !important;
  --dark-border :#30314e;
  --dark-primary :#A1247B !important;
  --transparent-primary :#A1247B !important;
  --transparent-body :#32428f;
  --transparent-theme : rgba(0,0,0,0.2);
  --transparent-border :$white-1;
  --blue: $dark-body;
  --dark-primary-hover :rgba(77, 101, 217, 0.8);
  --primary-transparentcolor:#f1f2f7;
  --darkprimary-transparent:rgba(255, 255, 255, 0.1);;
  --transparentprimary-transparentcolor:rgba(255, 255, 255, 0.1);
  --indigo:#4b0082;
  --purple:#6f42c1;
  --pink:$secondary;
  --orange:#fd7e14;
  --yellow:#ffc107;
  --green:#28a745;
  --teal:#20c997;
  --cyan:#17a2b8;
  --white:$white;
  --gray:#6c757d;
  --gray-dark:#343a40;
  --primary:#A1247B !important;
  --secondary:#f1388b;
  --success:$success;
  --info:$info;
  --warning:$warning;
  --danger:$danger;
  --light:#f8f9fa;
  --dark:$dark;
  --breakpoint-xs:0;
  --breakpoint-sm:576px;
  --breakpoint-md:768px;
  --breakpoint-lg:992px;
  --breakpoint-xl:1200px;
  --font-family-sans-serif:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace:SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}